var exports = {};
!function (e, t) {
  exports = t();
}(exports, function () {
  return function (e) {
    function t(r) {
      if (n[r]) return n[r].exports;
      var o = n[r] = {
        exports: {},
        id: r,
        loaded: !1
      };
      return e[r].call(o.exports, o, o.exports, t), o.loaded = !0, o.exports;
    }

    var n = {};
    return t.m = e, t.c = n, t.p = "", t(0);
  }([function (e, t, n) {
    e.exports = n(1);
  }, function (e, t) {
    "use strict";

    function n(e, t) {
      if (e) if (e.createTextRange) {
        var n = e.createTextRange();
        n.move("character", t), n.select();
      } else e.selectionStart ? (e.focus(), e.setSelectionRange(t, t)) : e.focus();
    }

    Object.defineProperty(t, "__esModule", {
      value: !0
    }), t["default"] = function (e) {
      var t = 0,
          n = 0;
      if (!e) return {
        start: t,
        end: n
      };
      if ("number" == typeof e.selectionStart && "number" == typeof e.selectionEnd) return {
        start: e.selectionStart,
        end: e.selectionEnd
      };
      if (!document) return {
        start: t,
        end: n
      };
      var r = document.selection.createRange();
      if (!r && r.parentElement() !== e) return {
        start: t,
        end: n
      };
      var o = e.value.length,
          a = e.value.replace(/\r\n/g, "\n"),
          c = e.createTextRange();
      c.moveToBookmark(r.getBookmark());
      var i = e.createTextRange();
      return i.collapse(!1), c.compareEndPoints("StartToEnd", i) > -1 ? t = n = o : (t = -c.moveStart("character", -o), t += a.slice(0, t).split("\n").length - 1, c.compareEndPoints("EndToEnd", i) > -1 ? n = o : (n = -c.moveEnd("character", -o), n += a.slice(0, n).split("\n").length - 1)), {
        start: t,
        end: n
      };
    }, t.setCaretPosition = n;
  }]);
});
export default exports;